<template>
  <div class="hl-full-container flexVertical">
    <!-- 表单s -->
    <el-drawer
      :title="dailogTitle"
      :visible.sync="dialogFormVisible"
      direction="rtl"
      size="100%"
      :modal="false"
      :destroy-on-close="true"
      class="el-drawer-hl"
      @close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :disabled="submitType == 'view' ? true : false"
        :rules="rules"
        size="medium"
        class="padding15"
        label-width="100px"
      >
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="辅具名称" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入辅具名称"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="租借时间">
                <el-date-picker
                  v-model="timeValue"
                  value-format="timestamp"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="租借时间"
                  end-placeholder="归还时间"
                  :default-time="['08:00:00', '08:00:00']"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="辅具图片">
                <el-upload
                  :file-list="fileList"
                  :headers="token"
                  :on-remove="handleRemove"
                  :on-success="success"
                  :before-upload="beforeUpload"
                  accept=".jpg, .png"
                  action="/api/rentalAuxiliary/uploadImg.json"
                  list-type="picture-card"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row>
            <el-col :span="12">
              <el-form-item label="数量" prop="number">
                <el-input
                  v-model="formData.number"
                  placeholder="请输入数量"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单价" prop="unitPrice">
                <el-input
                  v-model="formData.unitPrice"
                  placeholder="请输入单价"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="使用人" prop="user">
                <el-input
                  v-model="formData.user"
                  placeholder="请输入使用人"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" prop="userTel">
                <el-input
                  v-model="formData.userTel"
                  placeholder="请输入联系方式"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" prop="address">
                <el-input
                  v-model="formData.address"
                  placeholder="请输入地址"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row>
            <el-form-item size="large" style="text-align: center">
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qconfirm"
                type="primary"
                size="medium"
                @click="submitForm()"
                >提交</el-button
              >
              <el-button
                v-show="submitType != 'view' ? true : false"
                class="qcancle"
                size="medium"
                @click="resetForm()"
              >
                重置
              </el-button>
            </el-form-item>
          </el-row>
        </el-col>
      </el-form>
    </el-drawer>

    <div class="search">
      <el-form :model="form" ref="form" label-width="120px" inline>
        <el-form-item label="辅具名称">
          <el-input
            clearable
            v-model="form.name"
            placeholder="请输入辅具名称"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="dataRefresh"
            size="small"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <!-- <el-button type="success" plain size="mini" icon="el-icon-upload">
        导入
      </el-button>

      <el-button type="primary" plain size="mini" icon="el-icon-download">
        导出
      </el-button> -->

      <el-button
        @click="Add"
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
      >
        新增
      </el-button>

      <el-button
        @click="Del"
        type="danger"
        plain
        size="mini"
        icon="el-icon-delete"
      >
        删除
      </el-button>
    </div>
    <!-- 表格 -->
    <hltableZ
      :isPage="true"
      :isNumber="true"
      :isChecked="true"
      :apiurl="TableOptions.apiurl"
      :columnEdit="TableOptions.columnEdit"
      :tableColumn="TableOptions.column"
      :formSearch="form"
      :refreshFlag="TableOptions.refreshFlag"
      :selectedRows="TableOptions.selectedRows"
      @getSelectedRows="getSelectedRows"
      :handleCellStyle="cellStyle"
      @cellClick="cellClick"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-tooltip effect="dark" content="查看" placement="top">
          <el-button
            type="warning"
            size="mini"
            icon="el-icon-search"
            circle
            @click="View(selected.singlerow)"
          ></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="编辑" placement="top">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="Edit(selected.singlerow)"
          ></el-button>
        </el-tooltip>
      </div>
    </hltableZ>

    <el-image-viewer
      v-if="imgVisible"
      :on-close="() => (imgVisible = false)"
      :url-list="srcList"
    />
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  name: "fjzlgl",
  components: { hltableZ, ElImageViewer },
  data() {
    return {
      token: { token: localStorage.getItem("systoken") },
      dailogTitle: "",
      dialogFormVisible: false,
      submitType: "",

      imgVisible: false,
      srcList: [],
      // 附件预览列表
      imgList: [],
      fileList: [],
      xlsAreaAction: "/api/elderlyInfo/importData.json",
      //表单信息-s
      timeValue: [],
      formData: {
        name: "",
        unitPrice: "",
        img: "",
        number: "",
        user: "",
        userTel: "",
        address: "",
        rentalTime: "",
        returnTime: "",
      },
      //表单信息-e
      form: {
        name: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入辅具名称",
            trigger: "blur",
          },
        ],
        unitPrice: [
          {
            required: true,
            message: "请输入单价",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入数量",
            trigger: "blur",
          },
        ],
        user: [
          {
            required: true,
            message: "请输入使用人",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        userTel: [
          {
            required: true,
            pattern:
              /^(0|86|17951)?(13[0-9]|14[579]|15[012356789]|16[2567]|17[01235678]|18[0-9]|19[012356789])[0-9]{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      // 表格属性值
      TableOptions: {
        columnEdit: true,
        column: [
          {
            label: "辅具名称",
            prop: "name",
            checked: true,
          },
          {
            label: "辅具图片",
            prop: "img",
            checked: true,
            formatter: () => {
              return "查看";
            },
          },
          {
            label: "使用人",
            prop: "user",
            checked: true,
          },
          {
            label: "联系电话",
            prop: "userTel",
            checked: true,
          },
          {
            label: "地址",
            prop: "address",
            checked: true,
          },
          {
            label: "租借时间",
            prop: "rentalTime",
            checked: true,
            formatter: this.timeFormatter,
          },
          {
            label: "租借金额",
            prop: "rentalAmount",
            checked: true,
          },
        ],
        apiurl: "api/getRentalAuxiliaryList",
        refreshFlag: "",
        selectedRows: [],
        isChecked: false,
        isNumber: true,
      },
    };
  },

  mounted() {
    this.dataRefresh();
  },

  methods: {
    timeFormatter(row, column, cellValue) {
      return cellValue ? this.$timestampTostr(cellValue) : "";
    },
    // 查询
    dataRefresh() {
      this.TableOptions.refreshFlag = new Date().getTime().toString();
    },
    Add() {
      this.setformType("add", "新增");
    },
    Edit(row) {
      this.setformType("edit", "编辑");
      this.loadform(row);
    },
    loadform(row) {
      const rowinfo = Object.assign({}, row);
      this.formData = rowinfo;
      this.imgList = rowinfo.img.split(",");
      this.imgList.forEach((file) => {
        this.fileList.push({ url: file });
      });
      this.timeValue = [rowinfo.rentalTime, rowinfo.returnTime];
    },
    setformType(type, title) {
      this.dialogFormVisible = true;
      this.dailogTitle = title;
      this.submitType = type;
    },
    getSelectedRows(rows) {
      this.TableOptions.selectedRows = rows;
    },
    handleClose() {
      this.resetForm();
    },
    Del: function () {
      if (this.TableOptions.selectedRows.length == 0) {
        this.$message({
          type: "error",
          message: "请勾选下方表格中的数据",
        });
        return false;
      }
      var ids = [];
      this.TableOptions.selectedRows.forEach((key) => {
        ids.push(key.id);
      });
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delRentalAuxiliary", { ids: ids })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    resetForm() {
      const th = this;
      Object.keys(this.formData).forEach(function (key) {
        th.formData[key] = "";
      });
      this.imgList = [];
      this.fileList = [];
      this.timeValue = [];
    },
    submitForm() {
      if (this.imgList.length == 0) {
        this.$message("请选择辅具图片");
        return;
      }
      if (this.timeValue.length == 0) {
        this.$message("请选择租借时间");
        return;
      }
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const copyForm = Object.assign({}, this.formData);
          if (this.submitType == "add") {
            copyForm.id = "";
          }
          copyForm.img = this.imgList.join(",");
          copyForm.rentalTime = this.timeValue[0];
          copyForm.returnTime = this.timeValue[1];
          this.$store
            .dispatch("api/saveRentalAuxiliary", copyForm)
            .then((response) => {
              if (response.success) {
                this.$message.success("操作成功");
                this.dataRefresh();
              } else {
                this.$message.error(response.message);
              }
              this.dialogFormVisible = false;
            })
            .catch((error) => {
              this.dialogFormVisible = false;
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    View(row) {
      this.setformType("view", "查看");
      this.loadform(row);
    },
    /**
     * 附件上传
     */
    success(response, file) {
      this.imgList.push(response.result);
    },
    beforeUpload(file) {
      let isAccept = new RegExp("image/*").test(file.type);
      if (!isAccept) {
        this.$message.error("应该选择图片类型的文件");
      }
      return isAccept;
    },
    handleRemove(file, fileList) {
      this.imgList = [];
      if (fileList.length > 0) {
        fileList.forEach((item) => {
          this.imgList.push(item.response ? item.response.result : item.url);
        });
      }
    },
    // 单元格点击
    cellClick(row, column, cell, event) {
      if (column.property === "img") {
        if (row.img) {
          this.srcList = row.img.split(",");
          this.imgVisible = true;
        }
      }
    },
    cellStyle(row, column) {
      if (column.property === "img") {
        return {
          color: "#409EFF",
          cursor: "pointer",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
</style>
