<template>
  <div class="hl-full-container flexVertical">
    <el-dialog
      title="临淄慈善"
      width="55%"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :visible.sync="showEditer"
    >
      <div class="edit-view">
        <div class="edit-title">
          <el-form :model="editorForm" label-width="120px" inline>
            <el-form-item label="标题：" label-width="80px">
              <el-input
                style="width: 500px"
                size="small"
                v-model="editorForm.title"
                placeholder="请输入标题"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button size="small" @click="saveEditor" type="primary"
            >保存</el-button
          >
        </div>
        <div
          style="
            width: 100%;
            flex: 1;
            border: 1px solid #ccc;
            overflow-y: hidden;
            margin-top: 5px;
          "
        >
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: calc(100% - 80px)"
            v-model="editorForm.content"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </div>
      </div>
    </el-dialog>

    <div class="search">
      <el-form :model="form" ref="form" label-width="120px" inline>
        <el-form-item label="标题名称">
          <el-input
            size="small"
            v-model="form.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="toolbar">
      <el-button
        @click="showEditer = true"
        type="primary"
        plain
        size="mini"
        icon="el-icon-plus"
        >新增</el-button
      >
    </div>
    <!-- 表格 -->
    <hltableZ
      :isPage="true"
      :isNumber="table.isNumber"
      :isChecked="false"
      :apiurl="table.apiurl"
      :columnEdit="table.columnEdit"
      :tableColumn="table.column"
      :formSearch="form"
      :refreshFlag="table.refreshFlag"
      :selectedRows="table.selectedRows"
    >
      <div slot-scope="selected" slot="EditColumn">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="Edit(selected.singlerow)"
        >
          查看
        </el-button>
        <el-button
          size="mini"
          type="danger"
          icon="el-icon-delete"
          @click="Del(selected.singlerow)"
        >
          删除
        </el-button>
      </div>
    </hltableZ>
  </div>
</template>

<script>
import { hltableZ } from "@/components";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "lzcs",
  components: { Editor, Toolbar, hltableZ },
  data() {
    return {
      editor: null,
      showEditer: false,
      editorForm: {
        id: "",
        title: "",
        content: "<p>临淄慈善</p>",
      },
      toolbarConfig: {
        excludeKeys: ["uploadVideo"],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            server: "/api/charitable/uploadImg.json",
            maxNumberOfFiles: 10,
            timeout: 15 * 1000,
            headers: {
              token: localStorage.getItem("systoken"),
            },
          },
        },
      },
      mode: "default", // or 'simple'

      //表单信息-e
      form: {
        title: "",
      },
      // 表格属性值
      table: {
        columnEdit: true,
        column: [
          {
            label: "标题名称",
            prop: "title",
            checked: true,
          },
        ],
        apiurl: "api/getCharitableList",
        refreshFlag: "",
        selectedRows: [],
        isNumber: true,
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.dataRefresh();
  },
  destroyed() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
    },
    handleClose(done) {
      // this.$confirm("确认关闭后，未保存内容会清空。")
      //   .then((_) => {
      //     this.editorForm = {
      //       id: "",
      //       title: "",
      //       html: "<p>临淄慈善</p>",
      //     };
      //     done();
      //   })
      //   .catch((_) => {});
      this.editorForm = {
        id: "",
        title: "",
        html: "<p>临淄慈善</p>",
      };
      done();
    },
    Edit(row) {
      this.editorForm = row;
      this.showEditer = true;
    },
    Del(row) {
      this.$confirm("确定要进行删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store
          .dispatch("api/delCharitable", { ids: [row.id] })
          .then((response) => {
            if (response.success) {
              this.$message.success("操作成功");
              this.dataRefresh();
            } else {
              this.$message.error(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 查询
    dataRefresh() {
      this.table.refreshFlag = new Date().getTime().toString();
    },
    saveEditor() {
      if (!this.editorForm.title) {
        this.$message.error("请输入标题");
        return;
      }
      const copyForm = Object.assign({}, this.editorForm);
      this.$store
        .dispatch("api/saveCharitable", copyForm)
        .then((response) => {
          if (response.success) {
            this.$message.success("操作成功");
            this.dataRefresh();
          } else {
            this.$message.error(response.message);
          }
          this.showEditer = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
@import "@/styles/tabletoptool.scss";
.edit-view {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
}

.edit-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/deep/ .el-form-item {
  margin-bottom: 0;
}
</style>
